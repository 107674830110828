@import '../../assets/scss/variables.scss';

.main-page.public-page {
  display: flex;
  align-items: center;
  justify-content: center;

  .box-wrapper {
    width: 1170px;
    @include border-radius(20px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 12px;
    padding: 20px;
    display: flex;
    height: 650px;

    .left-content {
      flex: 1 1 50%;
      max-width: 50%;
      min-width: 50%;
      text-align: center;

      h1.title {
        font-size: 16px;
        font-weight: normal;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }

    .right-content {
      flex: 1 1 50%;
      max-width: 50%;
      min-width: 50%;

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }

  button.my-btn.btn-login {
    background-color: $blue-dark;
    font-size: 18px !important;
    color: #fff;
    text-transform: none;
    width: 300px;
    box-shadow: none !important;
  }

  img.display-mobile {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .main-page.public-page {
    overflow: auto;
    .login-form {
      margin-bottom: 40px;
    }
    img.display-mobile {
      display: inline-block;
    }
    img.display-pc {
      display: none;
    }
    .signup-form {
      margin-bottom: 40px;
    }
    .login-auth-form {
      margin-bottom: 40px;
    }
    .box-wrapper {
      width: 100%;
      height: 100%;
      flex-direction: column;
      padding: 0;
      box-shadow: none;
      border-radius: 0;
      .left-content {
        background-color: $bg-gray;
      }
      .left-content,
      .right-content {
        flex: 1 1 100%;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }

}