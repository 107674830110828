.wrapper-my-modal {
    opacity: 1;
    background-color: #fff;
    padding: 30px;
    max-width: 80%;
    max-height: 90vh;
    overflow: auto;
    min-width: 800px;
    position: relative;
    &.small-size {
      min-width: 400px;
      max-width: 400px;
    }
    
    .button-submit-right {
      text-align: right;
      margin: 10px 0;
      button {
        margin-right: 15px;
        min-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .head-modal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 30px 0;
      h1.title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        text-align: left;
        margin: 0;
      }
      button.MuiIconButton-root {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
    &.my-modal-form-edit {
      min-width: 1024px;
      .items-inline {
        margin: 0 -10px;
      }
      .items-inline .item-input {
        padding: 0 10px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .wrapper-my-modal {
      min-width: unset !important;
      &.small-size {
        min-width: unset !important;
      }
    }
  }