@import '../../assets/scss/variables.scss';
.wrapper-booking-time {
    .title {
        font-weight: bold;
        margin-bottom: 20px;
    }
    .row-button {
        button {
            margin-right: 15px;
            min-width: 152px;
            margin-bottom: 15px;
            &.active {
                background-color: $green;
                color: #fff;
                border: none;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
