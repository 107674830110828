@import '../../assets/scss/variables.scss';

header.header-app {

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 56px;
    height: 40px;
    right: 10px;
    top: 22px;
    border:1px solid rgba(0,0,0,.1);
    border-radius: 0.25em;
    background: url('../../assets/images/icon-menu.svg') no-repeat center center;
    background-size: 30px 30px;
    >span {
      display: none;
    }
    button {
      opacity: 0;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 40px !important;
    width: 40px !important;
    button {
      opacity: 0;
    }
    > span {
      top: 8px !important;
      right: 20px !important;
    }
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #fff;
    height: 24px !important;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100% !important;
    width: 100% !important;
    top: 0;
    left: 0;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #24558d;
    padding: 0;
    opacity: 0.97;
    overflow: hidden !important;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    padding: 50px;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-decoration: none !important;
    &.active {
      color: #000;
      font-weight: bold;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    background: transparent !important;
  }

  .wrapper-menu-mobile {
    display: none;
    button.btn-logout {
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding: 0;
    }
    .logo {
      display: inline-flex;
      height: 100%;
      align-items: center;
      a {
        display: inline-flex;
        align-items: center;
        img {
          width: 270px;
        }
      }
    }
  }

  .wrapper-menu-pc {
    height: 40px;

    ul {
      display: inline-flex;
      align-items: center;
      li {
        display: inline-flex;
        margin-right: 15px;
        &.parent-menu {
          position: relative;
          ul.child-menu {
            position: absolute;
            left: 0;
            top: 18px;
            background-color: #fff;
            z-index: 1;
            padding: 15px;
            display: none;
            border: 1px solid #dfdfdf;
            li {
              white-space: nowrap;
              &:first-child {
                margin-bottom: 5px;
              }
            }
          }
          &:hover {
            .child-menu {
              display: block;

            }
          }
        }

        a, span.name-parent {
          cursor: pointer;
          font-size: 14px;
          text-transform: uppercase;
          color: $body-color;
          text-decoration: none !important;
          font-weight: bold;
          &:hover, &.active {
            color: $blue-dark;
          }
        }
      }
    }
  }

  .wrapper-menu-pc {
    height: 85px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    .container-app {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right-content {
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        .user-account {
          button {
            background-color: transparent;
            padding: 0;
            background-image: url('../../assets/images/icon-user.png');
            background-repeat: no-repeat;
            box-shadow: none;
            border: none;
            min-width: 30px;
            @include border-radius(50%);
            background-position: center center;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

 

  @media only screen and (max-width: 768px) {
    .wrapper-menu-mobile {
      display: flex;
      justify-content: center;
      height: 85px;
      background-color: #fff;
    }

    .wrapper-menu-pc {
      display: none;
    }
  }
}

.content-dropdown-account {
  padding: 15px;
  width: 200px;
  .user-name {
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    margin-bottom: 20px;
  }
  button.btn-account {
    width: 100%;
    border: none;
    background: transparent;
    text-align: left;
    cursor: pointer;
    margin-bottom: 10px;
    &:hover {
      color: $blue;
    }
  }
}