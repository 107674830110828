@import '../../assets/scss/variables.scss';

.wrapper-booking-schedule-page {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: $margin-bottom-page;

    h1.title {
        text-align: center;
        font-size: 30px;
        color: #1C508A;
        padding: 0;
        margin: 40px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    .wrapper-content {
        display: flex;
        .left-content, .right-content {
            flex: 1 1 50%;
            max-width: 50%;
            min-width: 50%;
            
            .box-info {
                background-color: #fff;
                padding: 0 20px 20px 20px;
                border-radius: 5px;
                overflow: hidden;
                margin-bottom: 30px;
                &.box-info-first {
                    padding-bottom: 72px;
                }
                &.no-padding {
                    padding: 0;
                    .box-title {
                        width: 100%;
                        margin-left: 0;
                        margin-bottom: 0;
                    }
                }
                .box-title {
                    background-color: $green;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    width: calc(100% + 40px);
                    margin-left: -20px;
                    padding: 0 20px;
                    box-sizing: border-box;
                    height: 36px;
                    margin-bottom: 20px;
                }
                .top-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    .name {
                        background: url('../../assets/images/user-blue.png') no-repeat left center;
                        font-size: 18px;
                        color: $blue-dark;
                        font-weight: bold;
                        padding-left: 40px;
                        height: 30px;
                        display: inline-flex;
                        align-items: center;
                    }

                    .title {
                        font-size: 18px;
                        color: $blue-dark;
                        font-weight: bold;
                        height: 30px;
                        display: inline-flex;
                        align-items: center;
                    }

                }

                .content-info {
                    .row-content-info {
                        display: flex;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .col-content-info {
                            height: 30px;
                            display: inline-flex;
                            align-items: center;
                            flex: 1 1 100%;

                            &:first-child {
                                padding-left: 40px;
                                flex: 1 1 auto;
                                max-width: 180px;
                                min-width: 180px;
                                width: 180px;
                            }

                            &.birthday {
                                background: url('../../assets/images/birthday-icon.png') no-repeat left center;
                                background-size: 22px;

                            }

                            &.sex {
                                background: url('../../assets/images/icon-sex.png') no-repeat left center;
                                background-size: 21px 16px;

                            }

                            &.phone {
                                background: url('../../assets/images/phone-icon.png') no-repeat left center;
                                background-size: 18px;
                            }

                            &.address {
                                background: url('../../assets/images/address-icon.png') no-repeat left center;
                                background-size: 18px;
                            }
                        }
                    }
                }
            }
        }
        .left-content {
            padding-right: 30px;
            box-sizing: border-box;
        }
        .right-content {
            padding-left: 30px;
            box-sizing: border-box;
           .detail-paper {
            .title-paper {
                color: $blue;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .row-paper {
                display: flex;
                margin-bottom: 5px;
                .col-paper {
                    &:first-child {
                        font-weight: bold;
                        min-width: 130px;
                        width: 240px;
                    }
                }
            }
           }
          
        }
     
    }
    .booking-confirm {
        text-align: center;
            margin-top: 60px;
            button.btn-booking-confirm {
                min-width: 200px;
            }
        &.display-mobile {
            display: none;
        }
       
    }


}
@media only screen and (max-width: 1320px) {
    .wrapper-booking-schedule-page .wrapper-content .right-content {
        padding-left: 10px;
        box-sizing: border-box;
    }
    .wrapper-booking-schedule-page .wrapper-content .left-content {
        padding-right: 10px;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 768px) {
    .wrapper-booking-schedule-page .wrapper-content .left-content .box-info .content-info .row-content-info .col-content-info, .wrapper-booking-schedule-page .wrapper-content .right-content .box-info .content-info .row-content-info .col-content-info {
        height: auto;
        min-height: 30px;
        &:first-child {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
        }
    }
    .wrapper-booking-schedule-page .wrapper-content .booking-confirm {
       display: none;
       
    }
    .booking-confirm {
        text-align: center;
        &.display-mobile {
            display: block !important;
           }
    }
    .wrapper-booking-time .row-button button {
        margin-bottom: 15px;
    }
    .wrapper-booking-schedule-page .wrapper-content {
        flex-wrap: wrap;
        .left-content, .right-content {
            margin: 0;
            padding: 0;
            flex: 1 1 100%;
            min-width: 100%;
            max-width: 100%;
        }
    }
    
    .wrapper-booking-schedule-page {
        margin-bottom: 40px;
    }
    .wrapper-booking-schedule-page h1.title {
        font-size: 26px;
    }


}