@import '../../assets/scss/variables.scss';

.form-create-profile {
    .title {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        margin: 0 0 30px 0;
    }

    .text-description {
        background-color: #c7e5f5;
        padding: 20px;
        margin-bottom: 30px;
    }
    .button-submit-center {
        text-align: center;
        margin-top: 30px;
        button {
            min-width: 130px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .form-create-profile .title {
        font-size: 16px;
    }
}