@import '../../assets/scss/variables.scss';

footer.footer-app {
  display: flex;
  justify-content: center;
  background-color: $blue-dark;

  .logo-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;

    a {
      display: flex;
    }
  }

  .container-app {
    padding: 20px 10px 40px 10px;
  }

  .content-footer {
    display: flex;

  }

  .info {
    color: #fff;
    flex: 1 1 33.333%;
    min-width: 33.333%;
    max-width: 33.333%;
    ul {
      li {
        min-height: 40px;
        margin-bottom: 15px;
        padding-left: 50px;

        &.email {
          background: url('../../assets/images/mail-icon.png') no-repeat left center;
        }

        &.phone {
          background: url('../../assets/images/footer-phone-icon.png') no-repeat left center;
        }

        &.address {
          background: url('../../assets/images/footer-address-icon.png') no-repeat left center;
        }
      }
    }
  }

  .service {
    color: #fff;
    flex: 1 1 33.333%;
    min-width: 33.333%;
    max-width: 33.333%;
  }
  .feedback {
    color: #fff;
    flex: 1 1 33.333%;
    min-width: 33.333%;
    max-width: 33.333%;
  }

  h2.title {
    font-weight: bold;
    padding: 0;
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
  }
  .feedback-form {
    button.btn-send-feedback {
      background-color: #fff !important;
      color: $body-color !important;
      text-transform: none;
    }
    .form-group {
      margin-bottom: 10px !important;
      .label-config {
        color: #fff;
      }
    }
    .form-group input.form-control, .form-group textarea.form-control {
      border-radius: 0 !important;
      border: none !important;
      border-bottom: 1px solid #fff !important;
      color: #fff !important;
      padding: 5px 0 !important;
      
    }
    .form-group input.form-control {
      height: 30px !important;
      line-height: 30px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .content-footer {
      flex-wrap: wrap;
    }
    .service {
      margin-bottom: 30px;
      margin-top: 20px;
    }
    .info, .service {
      min-width: 100%;
    }
    .feedback {
      min-width: 100%;
    }
  }
}