.my-datetime-picker {
    width: 100%;
  
    label.MuiFormLabel-root {
      display: none;
    }
  
    .MuiInput-root {
      height: 36px;
      font-size: 16px;
      border: 1px solid $border-input;
      @include border-radius(5px);
      background-color: #fff;
      font-family: "Averta" !important;
      margin-top: 0px;
  
      &::before {
        display: none;
      }
  
      &::after {
        display: none;
      }
  
      &.Mui-focused {
        border: 1px solid $body-color;
  
        input.MuiInputBase-input {
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  
    input.MuiInputBase-input {
      font-family: "Averta" !important;
      font-size: 16px !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  
  
  .wrapper-my-datepicker {
    .react-datepicker {
      border: none;
      font-size: 18px;
      color: $blue-dark;
      font-family: "Averta";
      border-radius: 0;
      width: 100%;
  
      .react-datepicker__month-container {
        width: 100%;
      }
    }
  
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: $blue-dark;
      display: inline-block;
      width: 50px;
      line-height: 36px;
      text-align: center;
      margin: 0px 18px;
      box-sizing: border-box;
      font-weight: bold;
      font-size: 18px;
  
    }
  
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: 18px;
      color: $blue-dark;
      padding-bottom: 15px;
      padding-top: 5px;
  
    }
  
    .react-datepicker__day-names {
      background-color: $gray;
      border-top: 1px solid $border-input;
      border-bottom: 1px solid $border-input;
    }
  
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
      background-color: $green !important;
      color: #fff;
    }
  
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: $green !important;
      color: #fff;
    }
  
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: $green !important;
      color: #fff;
    }
  
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled {
      color: $yellow;
  
    }
  
    .react-datepicker__header {
      background-color: transparent;
      border: none;
    }
  
    .react-datepicker__navigation--next {
      right: 50%;
      margin-right: -120px;
      top: 12px
    }
  
    .react-datepicker__navigation--previous {
      left: 50%;
      margin-left: -120px;
      top: 12px
    }
  
    .react-datepicker__navigation--next {
      background: url('../images/arrow-right.svg') no-repeat center center;
      background-color: $blue-dark;
      width: 26px;
      height: 26px;
      @include border-radius(50%);
  
      .react-datepicker__navigation-icon--next {
        display: none;
      }
    }
  
    .react-datepicker__navigation--previous {
      background: url('../images/arrow-left.svg') no-repeat center center;
      background-color: $blue-dark;
      width: 26px;
      height: 26px;
      @include border-radius(50%);
  
      .react-datepicker__navigation-icon--previous {
        display: none;
      }
    }
  
  }
  