@import '../../assets/scss/variables.scss';

.result-img {
    display: flex;
    align-items: center;

    img {
        width: 100px;
        margin-right: 30px;
    }
}

.image-updated {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;

    .image-item {
        padding: 10px;

        img {
            width: 100%;
        }

        width: 100px;
    }
}

.upload-image {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .btn-upload-img {
        position: relative;
        width: 180px;
        height: 36px;
        background-color: $blue;
        @include border-radius(5px);
        margin-right: 15px;

        input {
            opacity: 0;
        }

        label {
            position: absolute;
            color: #fff;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 768px) {
    .upload-image {
        .btn-upload-img {
            margin-right: 0;
            margin-bottom: 10px;
        }

        justify-content: center;
        flex-wrap: wrap;
    }
}