@import '../../assets/scss/variables.scss';

.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  img {
    width: 200px;
    margin-bottom: 15px;
  }
  span {
    font-size: 20px;
  }
}