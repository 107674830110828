.my-tab {
    button.MuiTab-root {
      font-size: 16px !important;
      text-transform: none;
      font-family: "Averta";
      font-weight: bold;
  
      &.Mui-selected {
        color: $blue-dark;
      }
    }
  
    span.MuiTabs-indicator {
      background-color: $blue-dark;
      bottom: 5px;
    }
  }
  