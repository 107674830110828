@import '../../assets/scss/variables.scss';

.wrapper-account-page {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: $margin-bottom-page;
    min-height: 500px;
    h1.title {
        text-align: center;
        font-size: 30px;
        color: #1C508A;
        padding: 0;
        margin: 40px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    .content-account {
        display: flex;
        background-color: #fff;
        padding: 30px;
        .content-item {
            flex: 1 1 50%;
            min-width: 50%;
            max-width: 50%;
            box-sizing: border-box;
            .row-info {
                display: flex;
                margin-bottom: 15px;
                .col-info {
                    flex: 1 1 100%;
                    &:first-child {
                        min-width: 220px;
                        max-width: 220px;
                    }
                }
            }
            .top-head {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 20px;
                button.btn-edit {
                    margin-left: 30px;
                }
                h2.title {
                    text-align: left;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 18px;
                    margin: 0;
                }
            }
            
        }
    }

}

@media only screen and (max-width: 768px) {
    .wrapper-account-page {
        margin-bottom: 40px;
    }
    .wrapper-account-page h1.title {
        font-size: 26px;
    }
    .wrapper-account-page .content-account {
        flex-wrap: wrap;
        .content-item {
            flex: 1 1 100%;
            min-width: 100%;
            max-width: 100%;
            h2.title {
                font-size: 18px;
            }
        }
    }
    .wrapper-account-page .content-account .content-item .row-info .col-info {
        word-break: break-word;
    }
    .wrapper-account-page .content-account .content-item .row-info .col-info:first-child {
        min-width: 120px;
        max-width: 120px;
    }
    .wrapper-account-page .content-account .content-item {
        &.content-item-password {
            .row-info .col-info:first-child {
                min-width: 180px;
            }
        }
    }
   
}