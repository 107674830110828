@import '../../assets/scss/variables.scss';

.wrapper-booking-page {
    width: 100%;
    margin-bottom: $margin-bottom-page;
    display: flex;
    justify-content: center;

    .content-booking {
        display: flex;
        margin-top: 30px;

        .left-content {
            flex: 1 1 auto;
            margin-right: 60px;
            min-width: 266px;
            max-width: 266px;

            .search-content {
                margin-top: 30px;

                .btn-search {
                    width: 100%;
                }

                .title {
                    display: flex;
                    margin-bottom: 15px;

                    .text {
                        font-size: 20px;
                        font-weight: bold;
                        color: $blue-dark;
                        flex: none;
                        margin-right: 15px;
                    }

                    .line {
                        flex: 1 1 100%;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            background-color: $green;
                            left: 0;
                            bottom: 5px;
                        }
                    }

                }

                .form-search {
                    background-color: #fff;

                    .search-form {
                        padding: 15px;
                    }
                }
            }

            ul.type-booking {
                background-color: #fff;

                li {
                    button {
                        background-color: #fff;
                        color: #fff;
                        width: 100%;
                        border-radius: 0;
                        height: 48px;
                        color: $body-color;
                        text-transform: none;
                        justify-content: flex-start;
                        box-sizing: border-box;
                        padding: 6px 12px;
                    }

                    &.active {
                        button {
                            background-color: $blue;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .right-content {
            flex: 1 1 100%;
            background-color: #fff;
            padding: 15px;
            box-sizing: border-box;
            position: relative;
            .general-info {
                display: flex;
                margin-bottom: 60px;

                .image-hospital {
                    max-width: 400px;
                    min-width: 300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        object-fit: cover;
                        width: 100%;
                    }
                }

                .info {
                    padding-left: 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .top {
                        .name {
                            background: url('../../assets/images/icon-khoa.png') no-repeat left center;
                            font-size: 18px;
                            color: #1C508A;
                            font-weight: bold;
                            padding-left: 40px;
                            height: 30px;
                            display: inline-flex;
                            align-items: center;
                        }
                        .address {
                            background: url('../../assets/images/icon-location.png') no-repeat left center;
                            font-size: 16px;
                            color: #1C508A;
                            padding-left: 40px;
                            height: 40px;
                            display: inline-flex;
                            align-items: center;
                            margin-top: 5px;
                            width: 100%;
                        }
                        .description {
                            margin: 15px 0 20px 0;
                        }
                    }

                    .bottom {
                        button {
                            margin-right: 15px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .my-pagination {
                margin-bottom: 30px;
            }

            .wrapper-list-data {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 40px;
                flex-direction: column;
                &.min-height {
                    position: relative;
                    min-height: 400px;
                }
                h2.title {
                    font-size: 20px;
                    font-weight: bold;
                    color: $blue-dark;
                    width: 100%;
                    margin: 0;
                    display: flex;
                    align-items: center;

                    span.text {
                        flex: none;
                        padding-right: 15px;
                    }

                    span.line {
                        flex: 1 1 100%;
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            background-color: $green;
                            left: 0;
                            bottom: -8px;
                        }
                    }

                }

                .wrapper-column {
                    background-color: #fff;
                    padding: 0 15px 15px 15px;
                    border: 1px solid $green;
                    @include border-radius(0px);

                    .avatar-image {
                        width: 100%;
                        height: auto;
                        min-height: 280px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            max-width: 100%;
                        }
                    }


                    .name {
                        font-size: 18px;
                        font-weight: bold;
                        margin: 15px 0 10px 0;
                        color: $blue-dark;
                    }

                    .info-job {
                        margin-bottom: 40px;

                        li {
                            font-size: 16px;
                            padding: 10px 0 10px 30px;
                            min-height: 40px;
                            box-sizing: border-box;

                            &.department {
                                background: url('../../assets/images/chuyen-khoa-icon.svg') no-repeat left center;
                                background-size: 17px 20px;

                            }

                            &.hospital {
                                background: url('../../assets/images/pin-gray-icon.svg') no-repeat left 10px;
                                background-size: 17px 26px;
                                min-height: 80px;

                            }

                            &.experience {
                                background: url('../../assets/images/badge-gray-icon.svg') no-repeat left center;
                                background-size: 17px 20px;

                            }
                        }
                    }

                    button.btn-detail {
                        width: 100%;
                    }
                    &.hospital-column {
                        .address {
                            font-size: 16px;
                            color: $blue-dark;
                            padding: 10px 0 10px 30px;
                            background: url('../../assets/images/pin-gray-icon.svg') no-repeat left 10px;
                            background-size: 17px 26px;
                            margin-bottom: 20px;
                            min-height: 80px;
                        }
                
                        
                        .btn-detail {
                            text-align: center;
                            padding: 0 30px;
                            button.btn-detail {
                                width: 100%;
                            }
                        }
                    }
                }

                .list-data {
                    margin-top: 30px;
                    width: 100%;
                }
            }
        }

    }

    
}

@media only screen and (max-width: 768px) {
    .wrapper-booking-page {
        margin-bottom: 40px;

        .content-booking {
            flex-direction: column;

            .left-content {
                flex: 1 1 100%;
                margin: 0;
                min-width: 100%;
                max-width: 100%;
                margin-bottom: 30px;
            }

            .right-content {
                flex: 1 1 100%;
                margin: 0;
                min-width: 100%;
                max-width: 100%;
                .wrapper-list-data {
                    min-height: 400px;
                }
                .general-info {
                    flex-direction: column;
                    .image-hospital {
                        max-width: 100%;
                        margin-bottom: 15px;
                    }
                    .info {
                        padding-left: 0;
                        .bottom {
                            display: flex;
                            margin-top: 30px;
                            justify-content: space-between;
                            button {
                                min-width: 45%;
                                margin: 0;
                            }
                        }
                    }
                }

            }
        }
    }
   
}


